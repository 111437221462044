import { useSlate } from "slate-react";
import saveAs from "file-saver";

const SaveFile = () => {
  let editor = useSlate();
  const handleClick = () => {
    let buffer = editor.saveDocx();
    // let blob = new Blob([buffer], { type: "application/octet-stream" });
    var xhr = new XMLHttpRequest();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    console.log(urlParams);
    const loc = urlParams.get("outputFileUrl");
    console.log(loc);
    xhr.open("POST", loc, true);
    xhr.setRequestHeader("Content-Type", "application/json");

    xhr.send(
      JSON.stringify({
        document: buffer.toString("base64"),
      })
    );
    // saveAs(blob, `demo.docx`);
  };
  return (
    <div>
      <button onClick={handleClick}>EasySign</button>
    </div>
  );
};

export default SaveFile;
