import React from "react";
import SlateEditor from "./SlateEditor";
import "../styles/mainContainer.css";

const MainContainer = () => {
  return (
    <main className="container">
      <SlateEditor />
    </main>
  );
};

export default MainContainer;
